@import '../base';

/* @define Person */
.Person {
  display: flex;
  align-items: flex-start;
  position: relative;
}

.Person-figure {
  flex: 0 0 24vw;
  max-width: 180px;
  margin-right: 2.2rem;
  position: relative;
  overflow: hidden;
  border-radius: 100%;
  opacity: 1;
  transition: opacity 200ms ease;
}

.Person--small .Person-figure {
  max-width: 4rem;
  margin-right: 1rem;
}

@media (min-width: 600px) and (max-width: 1200px) {
  .Person--small .Person-figure {
    max-width: 3rem;
  }
}

.Person-figure::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.Person-image {
  min-width: 100%;
  min-height: 100%;
  max-height: 100%;
  width: auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: rgb(var(--color-gray));
}

@supports (object-fit: cover) {
  .Person-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transform: none;
  }
}

.Person.is-loading .Person-image {
  background-color: rgba(var(--loading-color));
}

.Person-info {
  flex: 1 1 auto;
}

.Person-title {
  font-size: 1.25rem;
  line-height: 1.2;
  font-weight: 700;
  margin-bottom: 0.2em;
  margin-top: 0.1em;
}

.Person--small .Person-title {
  font-size: 1rem;
  font-weight: 400;
}

@media (min-width: 900px) {
  .Person-title {
    margin-top: 0.7rem;
  }
}

.Person--small .Person-title {
  margin-top: 0;
}

.Person-info a:not(.Person-link):hover {
  color: rgb(var(--document-color));
}

.Person-link,
.Person-text a {
  display: inline-block;
  text-decoration: underline;
  text-decoration-thickness: 1.5px;
  text-underline-offset: 0.14em;
  padding: 0.1em 0.3em;
  margin: 0 -0.3em;
  border-radius: 0.1875rem;
  color: rgb(var(--color-turquoise));
  transition: background 400ms var(--ease-out);
}

.Person-link:hover,
.Person-text a:hover {
  background: rgba(var(--color-turquoise), 0.05);
  transition: none;
}

.Person-link:active,
.Person-text a:active {
  opacity: 0.6;
}

.Person-text {
  color: rgb(var(--color-muted));
  margin: 0.3em 0;
}

.Person--small .Person-text {
  font-size: 0.9rem;
}
