@import '../base';

/* @define Book */
.Book {
  padding: 3rem 0 1.5rem;
  background: rgb(var(--color-gray));
}

@media (min-width: 800px) and (min-height: 400px) {
  .Book {
    padding-top: 5rem;
  }
}

.Book-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.Book-figure {
  margin-bottom: 1rem;
  padding: 0 2rem 0 1rem;
}

@media (min-width: 1000px) {
  .Book-content {
    align-items: center;
  }
}

@media (min-width: 600px) {
  .Book-figure {
    flex: 0 1 33.333%;
    padding-right: 2rem;
    margin-right: 0.5rem;
  }
}

@media (min-width: 1000px) {
  .Book-figure {
    flex: 0 1 50%;
    padding: 0 4rem;
  }
}

@media (min-width: 1200px) {
  .Book-figure {
    margin-right: 1.5rem;
  }
}

.Book-image {
  display: block;
  width: 100%;
  height: auto;
}

.Book-body {
  flex: 1 1 0%;
}

.Book-title {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.25;
  color: rgb(var(--color-blue));
}

@media (min-width: 800px) and (min-height: 400px) {
  .Book-title {
    font-size: 2.75rem;
    line-height: 1.2;
  }
}

.Book-rating {
  display: block;
  margin: 0.5em 0;
  position: relative;
  font-size: 0.875rem;
}

.Book-rating::before {
  content: '★★★★★';
  position: absolute;
  color: rgb(var(--color-muted));
}

.Book-rating::after {
  content: attr(data-rating);
  position: relative;
  color: #ff9300;
}

.Book-author {
  display: block;
  margin: 0.5em 0 1em;
  font-size: 0.875rem;
}

.Book-footer {
  flex: 1 0 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 1.5rem 0 0;
  margin-top: 1.5rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.Book-retailers {
  display: none;
  font-size: 0.875rem;
  color: rgb(var(--color-muted));
}

@media (min-width: 600px) {
  .Book-footer {
    margin-top: 2rem;
  }

  .Book-retailers {
    display: flex;
    align-items: center;
    margin-right: 1rem;
  }
}

.Book-logotype {
  display: block;
  width: auto;
  max-height: 0.9rem;
  margin-left: 1rem;
}
