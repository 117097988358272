@import '../global';

.u-loading,
.u-loadingLight {
  background: rgba(var(--loading-color)) !important;
  color: transparent !important;
  user-select: none !important;
  line-height: 1.4 !important;
}

.u-loadingLight {
  background: rgba(var(--loading-color-solid)) !important;
}
