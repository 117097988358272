@import '../base';

/* @define Button */
.Button {
  display: inline-block;
  position: relative;
  overflow: hidden;
  padding: 1em 1.25em;
  border: 0;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1;
  white-space: nowrap;
  border-radius: 0.1875rem;
  text-decoration: none;
  cursor: pointer;
  color: rgb(var(--color-blue));
  background: rgb(var(--color-gray));
  transition: background 100ms var(--ease-out);
}

.Button::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 300ms;
  background: rgb(var(--color-gray-dark));
  opacity: 0;
}

.Button:hover::before,
.Button:active::before {
  opacity: 1;
}

.Button:active::before {
  transition-duration: 50ms;
}

.Button span {
  position: relative;
  z-index: 1;
}

.Button[disabled] {
  opacity: 0.6;
  pointer-events: none;
}

/**
 * Themes
 */

.Button--gray {
  color: rgb(var(--color-blue));
  background: rgb(var(--color-gray));
}

.Button--gray::before {
  background: rgb(var(--color-gray-dark));
}

.Button--yellow {
  color: rgb(var(--color-blue));
  background: rgb(var(--color-yellow));
}

.Button--yellow::before {
  background: rgb(var(--color-yellow-dark));
}

.Button--orange {
  color: #fff;
  background: rgb(var(--color-orange));
}

.Button--orange::before {
  background: rgb(var(--color-orange-dark));
}

.Button--turquoise {
  color: #fff;
  background: rgb(var(--color-turquoise));
}

.Button--turquoise::before {
  background: rgb(var(--color-turquoise-dark));
}

.Button--pink {
  color: rgb(var(--color-blue));
  background: rgb(var(--color-pink));
}

.Button--pink::before {
  background: rgb(var(--color-pink-dark));
}

.Button--blue {
  color: #fff;
  background: rgb(var(--color-blue));
}

.Button--blue::before {
  background: rgb(var(--color-blue-dark));
}
