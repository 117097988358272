@import '../base';

/* @define Card */
:root {
  --Card-aspect: calc(10 / 12);
  --Card-tiltX: 0;
  --Card-tiltY: 0;
}

.Card {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  z-index: 0;
  color: rgb(var(--current-color));
}

@media print {
  .Card {
    page-break-inside: avoid;
    page-break-after: avoid;
  }
}

.Card-content {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  align-items: flex-start;
  padding: 0;
}

/**
 * 1. Get a z-index to put ontop of hover shading
 */

.Card-body {
  position: relative; /* 1 */
  width: 100%;
  max-width: 36rem;
}

.Card--image .Card-body {
  margin-top: 1.375rem;
}

.Card-body a {
  position: relative;
  z-index: 1;
  transition: opacity 200ms var(--ease-out);
}

.Card-body a:hover {
  text-decoration: underline;
  text-decoration-thickness: 1.5px;
  text-underline-offset: 0.14em;
}

.Card-body a:active {
  opacity: 0.6;
  transition: none;
}

.Card-meta {
  display: block;
  margin-bottom: 0.75em;
  font-size: 0.875rem;
  font-style: normal;
  color: rgb(var(--color-muted));
}

.Card-title {
  margin-bottom: 0.5em;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1.25;
  max-width: 40rem;
}

@media (min-width: 1000px) {
  .Card-title {
    font-size: 1.75rem;
  }
}

.Card-text {
  margin: 0.5rem 0 0;
}

/**
 * Figure
 */

.Card-figure {
  max-height: 24rem;
  position: relative;
  overflow: hidden;
  background-color: rgba(var(--loading-color));
}

.Card-figure::before {
  content: '';
  display: block;
  height: 0;
  padding-top: calc(100% * var(--Card-aspect));
}

.Card-figureIcon {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  display: block;
}

@media (min-width: 600px) {
  .Card-figureIcon {
    width: 67px;
    height: 67px;
  }
}

.Card-image {
  position: absolute;
  left: 50%;
  top: 50%;
  min-width: 100%;
  min-height: 100%;
  max-height: 100%;
  width: auto;
  transform: translate(-50%, -50%);
  border-radius: 0.375rem;
}

@supports (object-fit: cover) {
  .Card .Card-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transform: none;
  }
}

/**
 * Link
 */

.Card-link {
  display: inline-block;
  color: rgb(var(--color-turquoise));
  padding: 0.1em 0.3em;
  margin: 0.5rem -0.3em -0.1em -0.3em;
  border-radius: 0.1875rem;
  transition: background 400ms var(--ease-out);
  text-decoration: underline;
  text-decoration-thickness: 1.5px;
  text-underline-offset: 0.14em;
  max-width: 32rem;
}

.Card-link::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.Card-link:active {
  opacity: 0.6;
}

.Card-link:hover {
  background: rgba(var(--color-blue), 0.05);
  transition: none;
}
