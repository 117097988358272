@import '../base';

/* @define Facts */
.Facts {
  padding: 3rem var(--document-margin);
  background-color: rgb(var(--color-gray));
}

.Facts-heading {
  font-size: 1.375rem;
  margin-bottom: 1rem;
  font-weight: 700;
}

@media (min-width: 600px) and (min-height: 400px) {
  .Facts {
    padding: 3rem 1.5rem;
    border-radius: 0.375rem;
  }
}

@media (min-width: 800px) and (min-height: 400px) {
  .Facts {
    padding: 4rem 3.25rem;
  }
}

@media (min-width: 900px) {
  .Facts-body {
    column-count: 2;
    column-gap: 3rem;
  }
}

.Facts-body li,
.Facts-body p {
  break-inside: avoid-column;
}
