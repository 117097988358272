@import '../base';

/* @define Partners */

.Partners {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
  margin: 4rem -1rem 0;
  justify-content: center;
}

.Partners-item {
  padding: 0.5rem 1rem;
  margin-bottom: 1rem;
}

.Partners-logo {
  display: inline-block;
  width: auto;
  height: auto;
  max-height: 2.7rem;
  max-width: 7.5rem;
  user-select: none;
}

@media (min-width: 900px) {
  .Partners {
    margin: 4rem -1rem 0;
  }

  .Partners-item {
    padding: 0.5rem 1.5rem;
  }

  .Partners-logo {
    max-height: 4.5rem;
    max-width: 12rem;
  }
}

@media (min-width: 1000px) {
  .Partners {
    margin: 5.5rem -1rem 0;
  }
}
