@import '../base';

/* @define Quote */
.Quote {
  overflow: hidden;
}

.Quote-symbol {
  display: block;
  color: rgb(var(--color-turquoise));
  width: 3rem;
  margin-bottom: 1rem;
}

.Quote-content {
  max-width: var(--container-medium-width);
  margin: 0 auto;
  font-weight: 700;
}

.Quote figcaption {
  margin-top: 1rem;
  font-weight: 400;
}

.Quote em {
  font-style: normal;
}
