@import '../base';

/* @define Footer */
.Footer-navigation {
  background: rgb(var(--color-gray));
  padding: 3rem 0 2.5rem;
  color: rgb(var(--color-blue));
}

@media (min-width: 800px) {
  .Footer-navigation {
    padding: 4rem 0 3.5rem;
  }
}

@media (min-width: 1000px) {
  .Footer-navigation {
    padding-bottom: 1.8rem;
  }
}

.Footer--newsletter:not(.Footer--alt) .Footer-navigation {
  padding-top: 16rem;
}

@media (min-width: 800px) {
  .Footer--newsletter:not(.Footer--alt) .Footer-navigation {
    padding-top: 13rem;
  }
}

.Footer-menu {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.4rem;
}

.Footer-list {
  margin: 0.8rem 0;
}

.Footer-section.Footer-section {
  flex-basis: 50%;
  margin-bottom: 1rem;
}

@media (max-width: 599px) {
  .Footer-section.Footer-section {
    flex-basis: 100% !important;
    width: 100% !important;
  }
}

.Footer-item {
  margin: 0.4rem;
  margin-right: 1rem;
}

.Footer-link {
  display: inline-block;
  padding: 0.1rem 0.3rem;
  margin: -0.1rem -0.3rem;
  border-radius: 0.1875rem;
  transition: background 400ms var(--ease-out);
}

.Footer-link:link:hover,
.Footer-link:link:active {
  background: rgba(var(--color-blue), 0.05);
  transition: none;
}

.Footer-link--primary {
  font-weight: 700;
}

@media (min-width: 800px) {
  .Footer-link--primary {
    font-size: 1.375rem;
  }

  .Footer-item {
    margin-right: 2rem;
  }
}

@media (min-width: 600px) {
  .Footer-section {
    flex-basis: 33.33333%;
  }

  .Footer-title {
    font-size: 1.5rem;
  }
}

@media (min-width: 800px) {
  .Footer-section {
    flex-basis: 25%;
  }
}

@media (min-width: 1000px) {
  .Footer-section {
    flex-basis: 16.66666%;
  }
}

/**
 * Newsletter
 */

.Footer-newsletter {
  padding: 3rem 1.5rem 2rem;
  margin: -9.5rem 0 0;
  border-radius: 0.375rem;
  color: #fff;
  background-color: rgb(var(--color-blue));
  transform: translateY(50%);
}

@media (min-width: 600px) {
  .Footer-newsletter {
    margin-top: -4rem;
  }
}

@media (min-width: 1000px) {
  .Footer-newsletter {
    margin-top: -3rem;
  }
}

.Footer-form {
  width: 100%;
  margin: 2rem 0;
  position: relative;
}

.Footer-email {
  display: block;
  width: 100%;
  padding: 1em 1.25em;
  border: 0;
  max-width: 30rem;
  font-size: 1rem;
  line-height: 1;
  background-color: #fff;
  color: rgb(var(--color-blue));
  border-radius: 0.1875rem;
  -webkit-appearance: none;
}

.Footer-email:focus-visible,
.Footer-email:focus {
  outline: 0 !important;
  border: 0 !important;
}

.Footer-message {
  display: block;
  width: 100%;
  margin-top: 1rem;
  font-weight: 700;
  position: absolute;
}

.Footer-button {
  margin-top: 0.5em;
  text-align: left;
}

@media (min-width: 800px) {
  .Footer-newsletter {
    display: flex;
    align-items: center;
    padding: 3.5rem;
  }

  .Footer-newsletter p {
    max-width: 32em;
  }

  .Footer-form {
    flex: 0 1 45em;
    margin-left: 3rem;
  }
}

@media (min-width: 1100px) {
  .Footer-newsletter {
    margin-left: auto;
    margin-right: auto;
  }
}

.Footer-contact {
  max-width: 30rem;
  margin-left: auto;
  margin-right: auto;
}

@media (min-width: 1400px) {
  .Footer-contact {
    max-width: 48rem;
  }
}

/**
 * Meta section
 */

.Footer-meta {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center;
}

.Footer-home {
  display: inline-block;
  width: 6.8rem;
  transform-origin: center;
}

.Footer-logo {
  width: 8.5rem;
}

.Footer-copy {
  opacity: 0.5;
  font-size: 0.875rem;
  margin-top: 0.2rem;
  white-space: nowrap;
}

@media (min-width: 600px) {
  .Footer-meta {
    margin: 2rem 0 1rem;
  }

  .Footer-copy {
    display: block;
  }
}

@media (min-width: 1000px) {
  .Footer-meta {
    display: flex;
    flex-wrap: nowrap;
  }

  .Footer-meta .Footer-logo {
    position: relative;
    order: 1;
  }

  .Footer-meta .Footer-section {
    order: 2;
  }

  .Footer-meta .Footer-copy {
    order: 3;
  }

  .Footer-meta .Footer-list,
  .Footer-meta .Footer-copy,
  .Footer-meta .Footer-item,
  .Footer-meta .Footer-home {
    margin-top: 0;
    margin-bottom: 0;
  }
}

/**
 * Alternative version
 * Used when the footer is used as mobile navigation
 */

@media (max-width: 799px) {
  .Footer--alt .Footer-navigation {
    padding-top: 5rem;
    background: rgb(var(--color-gray));
    text-align: center;
    line-height: 1.35;
  }

  .Footer--alt .Footer-newsletter,
  .Footer--alt .Footer-contact {
    display: none;
  }

  .Footer--alt .Footer-meta {
    display: none;
  }

  .Footer-list {
    margin: 0.4rem 0 1.8rem;
  }

  .Footer--alt .Footer-menu {
    font-size: 1.125rem;
  }

  .Footer--alt .Footer-link--primary {
    font-size: 1.375rem;
  }

  .Footer--alt .Footer-copy {
    display: none;
  }

  .Footer--alt .Footer-menu {
    text-align: left;
  }

  .Footer--alt .Footer-meta {
    padding-top: 2rem;
    margin: 1.3rem 0;
  }
}

@media (min-width: 600px) {
  .Footer--alt .Footer-navigation {
    padding-top: 7rem;
  }
}
