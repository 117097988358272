@import '../base';

/* @define Grid */
:root {
  --Grid-gutter: 1.5rem;
  --Grid-gutter-vertical: 1.8rem;
}

@media (min-width: 800px), print {
  :root {
    --Grid-gutter: 2.8rem;
    --Grid-gutter-vertical: 2.5rem;
  }
}

@media (min-width: 1000px), print {
  :root {
    --Grid-gutter: 1.5rem;
    --Grid-gutter-vertical: 3rem;
  }
}

@media (min-width: 1200px), print {
  :root {
    --Grid-gutter: 2.8rem;
  }
}

.Grid {
  display: flex;
  flex-wrap: wrap;
  width: calc(100% + var(--Grid-gutter));
  margin: 0 calc(var(--Grid-gutter) * -0.5);
  margin-bottom: calc(var(--Grid-gutter-vertical) * -1);
}

.Grid--divided {
  margin-bottom: calc(var(--Grid-gutter-vertical) * -2);
  overflow: hidden;
}

.Grid--slim {
  --Grid-gutter: 0.6rem;
  --Grid-gutter-vertical: 0.6rem;
}

@media (min-width: 600px), print {
  .Grid--slim {
    --Grid-gutter: 1.5rem;
    --Grid-gutter-vertical: 1.5rem;
  }
}

/**
 * 1. Add support for truncated text within the grid
 */

.Grid-cell {
  flex: 0 0 100%;
  display: flex;
  flex-direction: column;
  min-width: 0; /* 1 */
  padding: 0 calc(var(--Grid-gutter) * 0.5);
  margin-bottom: var(--Grid-gutter-vertical);
}

.Grid--divided .Grid-cell {
  margin-bottom: calc(var(--Grid-gutter-vertical) * 2);
  margin-top: calc(var(--Grid-gutter-vertical) * -1);
}

.Grid-cell > * {
  flex-basis: 100%;
  min-width: 0; /* 1 */
}

.Grid--divided .Grid-cell::before {
  flex: 0 0 1px;
  content: '';
  display: block;
  width: 100%;
  height: 1px;
  margin-bottom: var(--Grid-gutter-vertical);
  background: rgba(0, 0, 0, 0.13);
}

.Grid-cell--appear {
  opacity: 0;
  transform: translateY(100px);
  animation: Grid-cell--appear 600ms cubic-bezier(0.23, 1, 0.32, 1) forwards;
}

@keyframes Grid-cell--appear {
  to {
    opacity: 1;
    transform: translateY(0px);
  }
}

/**
 * Carousel
 */

@media (max-width: 599px) {
  .Grid--carousel {
    width: 100%;
    margin: 0;
    overflow-x: auto;
    overflow-y: hidden;
    flex-wrap: nowrap;
    -webkit-overflow-scrolling: touch;
    -webkit-scroll-snap-type: mandatory;
    scroll-snap-type: x mandatory;
    -webkit-scroll-snap-points-x: repeat(100%);
    scroll-snap-points-x: repeat(100%);
  }

  .Grid--carousel .Grid-cell {
    flex-basis: calc(100% - 2rem);
    padding-left: var(--document-margin);
    padding-right: 0;
    margin-right: calc((var(--document-margin) * -1) + 1rem);
    scroll-snap-align: start;
    margin-bottom: 0;
  }

  .Grid--carousel .Grid-cell:last-child {
    padding-right: var(--document-margin);
    flex-basis: calc(100% - 1rem);
  }
}
