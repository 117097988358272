@import '../base';

/* @define Product */
.Product {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  padding: 1.5rem 1.5rem 3rem;
  border: 1px solid rgba(0, 0, 0, 0.13);
  border-radius: 0.375rem;
}

.Product + .Product {
  margin-top: 1.5rem;
}

@media (min-width: 600px) and (min-height: 350px) {
  .Product {
    padding: 3rem 1.5rem;
  }
}

@media (min-width: 800px) and (min-height: 400px) {
  .Product {
    padding: 3.5rem;
  }
}

.Product--standalone {
  border: 0;
  padding: 0;
}

.Product-figure {
  flex: 0 0 100%;
  margin-bottom: 2rem;
  position: relative;
  overflow: hidden;
  border-radius: 0.375rem;
}

@media (min-width: 600px) {
  .Product-figure {
    flex: 0 0 33.333%;
  }
}

.Product-figure::before {
  content: '';
  display: block;
  padding-top: 100%;
}

.Product-image {
  position: absolute;
  left: 50%;
  top: 50%;
  min-width: 100%;
  min-height: 100%;
  max-height: 100%;
  width: auto;
  transform: translate(-50%, -50%);
  background: rgb(var(--color-gray));
}

.Product.is-loading .Product-image {
  background-color: rgba(var(--loading-color));
}

@supports (object-fit: cover) {
  .Product-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    transform: none;
  }
}

.Product-header {
  flex: 1 1 100%;
}

@media (min-width: 600px) {
  .Product-header {
    flex: 1 1 0%;
    margin-left: 2rem;
  }
}

.Product-label {
  display: block;
  margin-bottom: 0.5rem;
}

.Product-title {
  margin-bottom: 0.5em;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.25;
}

@media (min-width: 800px) and (min-height: 400px) {
  .Product-title {
    font-size: 2.75rem;
    line-height: 1.2;
  }
}

.Product-properties {
  margin: 0 0 1rem;
}

.Product-value {
  display: inline-block;
}

.Product-value {
  margin: 0 1rem 0.5rem 0;
}

.Product-body {
  margin-bottom: 2rem;
}

.Product-heading {
  margin: 2rem 0 1rem;
  font-weight: 700;
}

.Product-features {
  padding-left: 1.5em;
}

.Product-feature {
  margin-bottom: 0.5em;
  position: relative;
  list-style-position: outside;
  list-style-image: url('./checkmark.svg');
}

@media (min-width: 1000px) {
  .Product {
    display: block;
    overflow: hidden;
  }

  .Product-figure,
  .Product-contact {
    float: left;
    clear: left;
    width: 33.333%;
  }

  .Product-header,
  .Product-body {
    float: right;
    width: 66.666%;
    padding-left: 4rem;
    margin-left: 0;
    position: relative;
    overflow: hidden;
  }
}
