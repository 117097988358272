@import '../base';

/* @define Date */
.Date {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 1.35rem 1.5rem;
  margin: 0.5rem 0;
  border: 1px solid rgba(0, 0, 0, 0.13);
  background-color: #fff;
  border-radius: 0.375rem;
  color: rgb(var(--color-muted));
}

.Date-content {
  width: 100%;
  padding-right: 1.5rem;
  margin-right: auto;
}

@media (min-width: 1000px) {
  .Date-content {
    max-width: calc(100% - 12rem);
  }
}

.Date-title {
  font-size: 1.125rem;
  color: rgb(var(--document-color));
}

.Date-action {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5rem;
}

.Date-meta {
  margin: 0 1.5rem 0.25rem 0;
}

@media (min-width: 800px) {
  .Date-meta {
    margin-bottom: 0;
  }
}
