/* @define utilities */

@import '../global';

.u-textRegular {
  font-weight: 400 !important;
}

.u-textBold {
  font-weight: 700 !important;
}

.u-textItalic {
  font-style: italic !important;
}

.u-textCenter {
  text-align: center !important;
}

.u-textNowrap {
  white-space: nowrap !important;
}

.u-textNowrap {
  white-space: nowrap !important;
}

.u-textUnselectable {
  user-select: none !important;
}

.u-textUppercase {
  text-transform: uppercase !important;
}

/**
 * Prevent text from wrapping onto multiple lines and truncate with an ellipsis.
 * 1. Ensure that the node has a maximum width after which truncation can occur.
 */

.u-textTruncate {
  display: block !important;
  max-width: 100% !important; /* 1 */
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
}

/**
 * Source: https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
 */

.u-textBreakLongWords {
  overflow-wrap: break-word;
  word-wrap: break-word;
  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  hyphens: auto;
}
