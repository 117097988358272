@import '../base';

/* @define Callout */
.Callout {
  position: relative;
  overflow: hidden;
  border-radius: 0.375rem;
  background-color: rgb(var(--color-gray));
  color: rgb(var(--color-blue));
}

.Callout-content {
  flex: 1 1 auto;
  padding: 2rem 1.5rem;
}

@media (min-width: 1000px) {
  .Callout-content {
    padding: 2.5rem;
  }
}

.Callout-body {
  margin: 1rem 0 0 0;
}

.Callout-label {
  display: block;
  margin-bottom: 0.5em;
  color: rgb(var(--color-muted));
}

.Callout-title {
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.15;
}

@media (min-width: 1000px) {
  .Callout-title {
    font-size: 2.5rem;
    line-height: 1.15;
    margin-top: -0.3rem;
  }
}

.Callout-figure {
  position: relative;
}

.Callout-image {
  display: block;
  width: 100%;
}

.Callout-link {
  align-self: flex-start;
  display: inline-block;
  color: rgb(var(--color-blue));
  padding: 0.1rem 0.3rem;
  margin: 1.25rem -0.3rem -0.1rem -0.3rem;
  border-radius: 0.1875rem;
  transition: background 400ms var(--ease-out);
  text-decoration: underline;
  text-decoration-thickness: 1.5px;
  text-underline-offset: 0.14em;
  max-width: 27em;
}

.Callout-link::before {
  content: '';
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.Callout-link:hover {
  background: rgba(var(--color-blue), 0.05);
  transition: none;
}

.Callout-link:active {
  opacity: 0.6;
}

@media (min-width: 350px) {
  .Callout-link {
    font-size: 1.125rem;
  }
}

@media (min-width: 1000px) {
  .Callout-link {
    font-size: 1.25rem;
  }
}

@media (min-width: 800px) {
  .Callout {
    display: flex;
  }

  .Callout-figure {
    flex-basis: 40%;
    padding: 2rem 0 2rem 2rem;
  }

  .Callout--right .Callout-figure {
    padding: 2rem 2rem 2rem 0;
  }

  .Callout-image {
    border-radius: 0.375rem;
  }

  .Callout:not(.Callout--image) .Callout-body {
    margin-top: 0.5rem;
  }

  .Callout:not(.Callout--image) .Callout-label {
    width: 100%;
  }

  .Callout:not(.Callout--image) .Callout-text {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .Callout:not(.Callout--image) .Callout-title,
  .Callout:not(.Callout--image) .Callout-body {
    flex: 0 1 auto;
    width: calc(50% - 1.5rem);
  }

  .Callout--image .Callout-content {
    flex-basis: 60%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .Callout--right .Callout-figure {
    margin-left: 0.5rem;
    order: 1;
    flex-wrap: nowrap;
  }

  .Callout--left .Callout-figure {
    margin-right: 0.5rem;
    order: 0;
    flex-wrap: nowrap;
  }
}

@media (min-width: 1000px) {
  .Callout-figure {
    flex-basis: 50%;
    padding: 2.5rem 0.75rem 2.5rem 2.5rem;
  }

  .Callout--right .Callout-figure {
    padding: 2.5rem 2.5rem 2.5rem 0.75rem;
  }

  .Callout--image .Callout-content {
    flex-basis: 50%;
  }

  .Callout--right .Callout-figure,
  .Callout--left .Callout-figure {
    margin: 0;
  }
}

@media (min-width: 1200px) {
  .Callout-figure {
    padding: 2.5rem 1.4rem 2.5rem 2.5rem;
  }

  .Callout--right .Callout-figure {
    padding: 2.5rem 2.5rem 2.5rem 1.4rem;
  }
}

/**
 * Themes
 */

.Callout--yellow {
  color: rgb(var(--color-blue));
  background-color: rgb(var(--color-yellow));
}

.Callout--yellow .Callout-link {
  color: rgb(var(--color-blue));
}

.Callout--orange {
  color: #fff;
  background-color: rgb(var(--color-orange));
}

.Callout--orange .Callout-link {
  color: rgb(var(--color-yellow));
}

.Callout--turquoise {
  color: #fff;
  background-color: rgb(var(--color-turquoise));
}

.Callout--turquoise .Callout-link {
  color: rgb(var(--color-yellow));
}

.Callout--pink {
  color: rgb(var(--color-blue));
  background-color: rgb(var(--color-pink));
}

.Callout--pink .Callout-link {
  color: rgb(var(--color-blue));
}

.Callout--blue {
  color: #fff;
  background-color: rgb(var(--color-blue));
}

.Callout--blue .Callout-link {
  color: rgb(var(--color-pink));
}
