@import '../base';
@import '../grid';

/* @define Course */
.Course {
  padding: 3rem 1.5rem;
  border-radius: 0.375rem;
  background-color: rgb(var(--color-gray));
}

.Course + .Course {
  margin-top: 1.5rem;
}

@media (min-width: 800px) and (min-height: 400px) {
  .Course {
    padding: 3.5rem;
  }
}

.Course-panel {
  padding: 2rem 1.5rem;
  border-radius: 0.375rem;
  border: 1px solid rgba(0, 0, 0, 0.13);
  background-color: #fff;
}

.Course-tag {
  display: inline-block;
  padding: 0.3em 0.8em;
  margin: 0 0.5em 0.5em 0;
  font-size: 0.75rem;
  border-radius: 0.1875rem;
}

.Course-title {
  margin-bottom: 0.5em;
  font-size: 2rem;
  font-weight: 700;
  line-height: 1.25;
}

@media (min-width: 800px) and (min-height: 400px) {
  .Course-title {
    font-size: 2.35rem;
    line-height: 1.2;
  }
}

.Course-title:not(:first-child) {
  margin-top: 0.25em;
}

.Course-link {
  display: inline-block;
  margin-top: 1em;
  padding: 0.1em 0.3em;
  margin: 1.5rem -0.3em -0.1em -0.3em;
  border-radius: 0.1875rem;
  transition: background 400ms var(--ease-out);
  text-decoration: underline;
  text-decoration-thickness: 1.5px;
  text-underline-offset: 0.14em;
}

.Course-link:hover {
  background: rgba(var(--color-blue), 0.05);
  transition: none;
}

.Course-link:active {
  opacity: 0.6;
}

.Course-heading {
  display: block;
  margin: 0 0 1em;
  font-weight: 700;
}

.Course-features {
  padding-left: 1.5em;
}

@media (min-width: 600px) and (max-width: 1200px) {
  .Course-features {
    font-size: 0.9rem;
  }
}

.Course-feature {
  margin-bottom: 0.5em;
  position: relative;
  list-style-position: outside;
  list-style-image: url('./checkmark.svg');
}

.Course-dates {
  margin-top: var(--Grid-gutter);
}
