:root {
  --color-yellow: 255, 203, 132;
  --color-yellow-dark: 218, 159, 79;
  --color-orange: 218, 101, 38;
  --color-orange-dark: 180, 79, 24;
  --color-turquoise: 7, 170, 184;
  --color-turquoise-dark: 4, 118, 128;
  --color-pink: 255, 179, 179;
  --color-pink-dark: 214, 128, 128;
  --color-blue: 17, 0, 70;
  --color-blue-dark: 0, 0, 0;
  --color-gray: 244, 243, 246;
  --color-gray-dark: 209, 208, 212;

  --color-muted: 17, 0, 70, 0.5;
  --color-link: var(--color-turquoise);

  --document-font-family: 'Basier Circle', -apple-system, BlinkMacSystemFont,
    'Segoe UI', Roboto, Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol';
  --document-font-size: 1rem;
  --document-line-height: 1.5;

  --document-background: 255, 255, 255;
  --document-color: 0, 0, 0;

  --document-width: 1600px;
  --document-margin: 1.2rem;

  --container-large-width: var(--document-width);
  --container-medium-width: 48rem;
  --container-small-width: 30rem;

  --loading-color: 17, 0, 70, 0.06;
  --loading-color-solid: 235, 235, 235;

  --focus-ring-width: 3px;
  --focus-ring-color: var(--color-blue);

  --ease-in: cubic-bezier(0.4, 0, 1, 1);
  --ease-out: cubic-bezier(0, 0, 0.2, 1);
  --ease: cubic-bezier(0.4, 0, 0.2, 1);
}

@media (min-width: 350px) {
  :root {
    --document-margin: 1.5rem;
  }
}

@media (min-width: 400px) {
  :root {
    --document-margin: 1.8rem;
  }
}

@media (min-width: 600px) {
  :root {
    --document-margin: 2.5rem;
  }
}

@media (min-width: 1000px) {
  :root {
    --document-margin: 3.5rem;
  }
}

@media (min-width: 1400px) {
  :root {
    --document-margin: 6rem;
  }
}
